<template>
  <header id="page-header">
    <h1 class="h1">Develop Common Agenda and Shared Measurement</h1>
  </header>
  <div class="container py-5">
    <div class="row mb-3 justify-content-center">
      <ul class="nav nav-tabs" id="caTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link active" id="about-tab" data-bs-toggle="tab" data-bs-target="#about" type="button" role="tab" aria-controls="about" aria-selected="true">About</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="ran-tab" data-bs-toggle="tab" data-bs-target="#ran" type="button" role="tab" aria-controls="ran" aria-selected="false">Common Agenda Framework</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="village-square-tab" data-bs-toggle="tab" data-bs-target="#village-square" type="button" role="tab" aria-controls="village-square" aria-selected="false">Measure Development and Data Source Worksheet</button>
        </li>
      </ul>
      <div class="tab-content" id="caTabContent">
        <!-- About Tab Content -->
        <div class="tab-pane pt-4 pb-2 px-1 fade show active" id="about" role="tabpanel" aria-labelledby="about-tab">
          <h2 class="h2">Agree to a common agenda</h2>
          <p class="p">Some of the hardest work collective impact initiatives do in the early stages is agree to a common agenda—defining the specific goals of the effort, and identifying precisely what will and won’t be included.</p>
          <p class="p">Mosaic Village makes starting this process a little easier because it comes with a default common agenda framework based on the work of our model organization, the 15th Night. While your community may choose to adjust the focus, or expand or narrow the defined boundaries of the Mosaic Village Common Agenda Framework, you have a solid starting place for your work, and a rationale for those boundaries.</p>
          <p class="p">You and your partners may struggle with defining and sticking to your common agenda, not because of what it contains, but what it excludes. That doesn’t mean that work that falls outside its scope is not important or shouldn’t be addressed; it simply means that your initiative’s ability to serve those it’s designed to serve depends on laser-like focus.</p>
          <p class="p">These boundaries, rather than holding you back, free you to put your energy where it will bear the most fruit in achieving your initiative’s purpose. They empower you and your partners to make a meaningful, measurable, and life-changing difference for young people in your community.</p>
          <h2 class="h2">Create a shared measurement system</h2>
          <p class="p">Shared measurement is a key component of successful collective impact initiatives because agreeing on <span class="fw-bold">what</span> specifically will be measured and <span class="fw-bold">how</span> it will be measured keeps everyone on the same page.</p>
        </div>
        <!-- CAF Tab Content -->
        <div class="tab-pane pt-4 pb-2 px-1 fade" id="ran" role="tabpanel" aria-labelledby="ran-tab">
          <div class="row py-2">
            <div class="col">
              <h2 class="h2">Common Agenda Framework</h2>
            </div>
          </div>
          <div class="row py-2">
            <CommonAgendaStatementComponent></CommonAgendaStatementComponent>
          </div>
          <div class="row py-2">
            <CommonAgendaWeServeComponent></CommonAgendaWeServeComponent>
          </div>
          <div class="row py-2">
            <CommonAgendaTwoColList listIndex="whowhy"></CommonAgendaTwoColList>
          </div>
          <div class="row py-2">
            <CommonAgendaOneColList listIndex="doby"></CommonAgendaOneColList>
          </div>
          <div class="row py-2">
            <CommonAgendaTwoColList listIndex="howwhy"></CommonAgendaTwoColList>
          </div>
        </div>
        <!-- Worksheet Tab Content -->
        <div class="tab-pane pt-4 pb-2 px-1 fade" id="village-square" role="tabpanel" aria-labelledby="village-square-tab">
          <div class="tab-pane fade show active" id="worksheet">
            <div class="row">
              <div class="col">
                <h2 class="h2 mb-4">Measure Development and Data Source Worksheet</h2>
                <h3 class="h3 mb-2">Initiative-Level Indicators</h3>
                <p>Measures you'll use to track progress toward your common agenda goals.</p>
                <CommonAgendaMeasurementTable listType="initiativeLevelIndicatorsList"></CommonAgendaMeasurementTable>
                <h3 class="h3 mb-2">Action Plan-Level Indicators</h3>
                <p>Measures related to action plan strategies and activities.</p>
                <CommonAgendaMeasurementTable listType="actionPlanLevelIndicatorsList"></CommonAgendaMeasurementTable>
                <h3 class="h3 mb-2">Contextual Indicators</h3>
                <p>Big-picture measures related to homelessness, graduation rates, etc.</p>
                <CommonAgendaMeasurementTable listType="contextualIndicatorsList"></CommonAgendaMeasurementTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container py-5">
    <div class="row">
      <div class="col">
        <router-link
          tag="button"
          class="btn btn-primary float-start"
          to="/youth-action-council">
          <i class="bi bi-chevron-left"></i>
        </router-link>
        <router-link
          tag="button"
          class="btn btn-primary float-end"
          to="/action-plan">
          <i class="bi bi-chevron-right"></i>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import CommonAgendaMeasurementTable from '@/components/CommonAgendaMeasurementTable';
import CommonAgendaStatementComponent from '@/components/CommonAgendaStatement';
import CommonAgendaWeServeComponent from '@/components/CommonAgendaWeServe';
import CommonAgendaTwoColList from '@/components/CommonAgendaTwoColList';
import CommonAgendaOneColList from '@/components/CommonAgendaOneColList';

export default {
  name: 'CommonAgendaPage',
  components: {
    CommonAgendaOneColList,
    CommonAgendaTwoColList,
    CommonAgendaWeServeComponent,
    CommonAgendaStatementComponent,
    CommonAgendaMeasurementTable,
  },
};
</script>

<style lang="scss" scoped>
h3 {
  font-size: 1rem;
  font-weight: 700;
}
.nav-tabs .nav-link.active {
  color: $info;
}
</style>
