<template>
  <div class="container-fluid mv-table-container mt-2 mb-4">
    <div class="d-print-none">
      <div class="row py-1">
        <div class="col fw-bold col-title">{{ col1Label }}</div>
      </div>
      <div class="row py-1" v-for="(entry, i) in records" :key="i">
        <div class="col-11">
          <!-- eslint-disable-next-line -->
          <label class="visually-hidden" :for="(listIndex + 'col1' + i)">{{ col1Label }}:</label>
          <textarea
            v-model="records[i].col1"
            @blur="updateRecord(listIndex, i, 'col1', records[i].col1);"
            :placeholder="(col1Label + '...')"
            class="form-control form-control-sm form-control-plaintext mv-table__input"
            type="text"
            :id="(listIndex + 'col1' + i)"
            :name="listIndex + ('col1' + i)">
          </textarea>
        </div>
        <!--<div class="col-1 d-flex align-items-center justify-content-center">
          <button class="btn btn-danger mv-icon-btn" aria-label="Delete Partner" @click="deleteRecord(listIndex, i);">
            <i class="bi bi-trash"></i>
          </button>
        </div>-->
      </div>
      <!--<div class="d-flex justify-content-end mt-2">
        <button class="btn btn-sm btn-secondary" @click="addRecord(listIndex)">+ Add Row</button>
      </div>-->
    </div>
    <div class="d-none d-print-block">
      <div class="row py-1">
        <div class="col fw-bold">{{ col1Label }}</div>
      </div>
      <div class="row py-1">
        <ul class="list-group">
          <li v-for="(entry, i) in records" :key="i" class="list-item mb-1 ms-5">{{ entry.col1 }}</li>
        </ul>
      </div>

    </div>
  </div>
</template>
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
export default {
  name: 'CommonAgendaOneColList',
  props: {
    listIndex: {
      type: String,
      required: true,
      default: '',
    },
    col1Label: {
      type: String,
      required: false,
      default: 'We do this by',
    },
  },
  setup(props) {
    const store = useStore();

    return {
      records: computed(() => {
        return store.state.measurement.listRecords[props.listIndex];
      }),
      addRecord: (type) => {
        store.dispatch('measurement/addListRecord', { type });
      },
      deleteRecord: (type, id) => {
        const c = confirm('Are you sure you want to delete this record?');
        if (c === true) {
          store.dispatch('measurement/deleteListRecord', { type, id });
        }
      },
      updateRecord: (type, id, prop, value) => {
        store.dispatch('measurement/updateListRecord', { type, id, prop, value });
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.col-title {
  font-weight: 700;
  font-size: .9rem;
}
.mv-table__input {
  background: $form-table-input-bg;
  border-radius:3px;
  border-width: 2px;
  color: $mv_secondary_blue;
  font-size: 14px;
  height: 100%;
  transition: $transition-default;
  width: 93%;
  word-break: break-word;
  &:focus,
  &:focus-visible {
    border-color: $info;
    box-shadow:none;
    outline: none;
  }
}
.mv-icon-btn {
  padding: 1px 4px;
  i {
    font-size: 1.1rem;
  }
}
.btn-info,
.btn-secondary,
.btn-success,
.btn-danger {
  &,
  &:link,
  &:visited,
  &:active,
  &:hover {
    color: $light;
  }
}
.form-control:read-only {
  background:transparent;
}
</style>
