<template>
  <div class="container-fluid mv-table-container mt-2 mb-4">
    <div class="row py-1">
      <div class="col-3 fw-bold col-title">{{ col1Label }}</div>
      <div class="col-9 fw-bold col-title">{{ col2Label }}</div>
    </div>
    <div v-for="(entry, i) in records" :key="i"  class="row py-1">
      <div class="col-3">
        <!-- eslint-disable-next-line -->
        <label class="visually-hidden" :for="(listIndex + 'col1' + i)">{{ col1Label }}:</label>
        <textarea
          v-model="records[i].col1"
          @blur="updateRecord(listIndex, i, 'col1', records[i].col1);"
          :placeholder="(col1Label + '...')"
          class="mv-table__input"
          type="text"
          :id="(listIndex + 'col1' + i)"
          :name="listIndex + ('col1' + i)"
          rows="5"></textarea>
      </div>
      <div class="col-8">
        <!-- eslint-disable-next-line -->
        <label class="visually-hidden" :for="(listIndex + 'col2' + i)">{{ col2Label }}:</label>
        <textarea
          v-model="records[i].col2"
          @blur="updateRecord(listIndex, i, 'col2', records[i].col2);"
          :placeholder="(col2Label + '...')"
          class="mv-table__input"
          type="text"
          :id="(listIndex + 'col2' + i)"
          :name="listIndex + ('col2' + i)"></textarea>
      </div>
      <div class="col-1 d-flex justify-content-center align-items-center">
        <button class="btn btn-outline mv-icon-btn d-print-none" aria-label="Delete Partner" @click="deleteRecord(listIndex, i);">
          <i class="bi bi-trash"></i>
        </button>
      </div>
    </div>
    <div class="d-flex justify-content-end mt-2">
      <button class="btn btn-sm btn-secondary d-print-none" @click="addRecord(listIndex)">+ Add Row</button>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
export default {
  name: 'CommonAgendaTwoColList',
  props: {
    listIndex: {
      type: String,
      required: true,
      default: '',
    },
  },
  setup(props) {
    const store = useStore();
    const col2Label = 'Why (rationale)';
    const col1Label = (props.listIndex === 'whowhy') ? 'Who' : 'How';

    return {
      col1Label,
      col2Label,
      records: computed(() => {
        return store.state.measurement.listRecords[props.listIndex];
      }),
      addRecord: (type) => {
        store.dispatch('measurement/addListRecord', { type });
      },
      deleteRecord: (type, id) => {
        const c = confirm('Are you sure you want to delete this record?');
        if (c === true) {
          store.dispatch('measurement/deleteListRecord', { type, id });
        }
      },
      updateRecord: (type, id, prop, value) => {
        store.dispatch('measurement/updateListRecord', { type, id, prop, value });
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.col-title {
  font-weight: 700;
  font-size: .9rem;
}
.mv-table__input {
  background: $form-table-input-bg;
  border: $input-group-input-border-base;
  border-radius: 3px;
  color: $mv_secondary_blue;
  font-size: 14px;
  height: 100%;
  padding: 4px 2px;
  transition: $transition-default;
  width: $form-table-input-width;

  &:focus,
  &:focus-visible {
    border-color: $info;
    outline: none;
  }
}
.mv-icon-btn {
  padding: 1px 4px;
  i {
    font-size: 1.1rem;
  }
}
.btn-info,
.btn-secondary,
.btn-success,
.btn-danger {
  &,
  &:link,
  &:visited,
  &:active,
  &:hover {
    color: $light;
  }
}
</style>
