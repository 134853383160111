<template>
  <!-- eslint-disable-next-line -->
  <label class="form-label mv-input-group__label" for="commonAgendaWeServe">We focus on serving</label>
  <textarea
    v-model="commonAgendaWeServe"
    class="form-control form-control-sm mv-input-group__input d-print-none"
    id="commonAgendaWeServe">
  </textarea>
  <div class="d-none d-print-block">{{ commonAgendaWeServe }}</div>
</template>
<script>
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  name: 'commonAgendaWeServeComponent',
  setup() {
    const store = useStore();
    return {
      commonAgendaWeServe: computed({
        get: () => store.state.measurement.commonAgendaWeServe,
        set: (value) => store.dispatch('measurement/updateCommonAgendaWeServe', { value }),
      }),
    };
  },
};
</script>
<style lang="scss" scoped>
.mv-input-group {
  max-width: $input-group-max-width;
  width: $input-group-width;
  &__label {
    color: $input-group-label-text-color;
    font-size: $input-group-label-font-size;
    font-weight: $input-group-label-font-weight;
    order:1;
  }
  &__input {
    border-radius: 3px;
    border-width: $input-group-input-border-width;
    color: $input-group-input-text-color;
    order:2;
    &:focus {
      border-color: $input-group-input-border-color-focus;
      box-shadow: none;
      outline: none;
    }
  }
}
.form-control:read-only {
  background: ghostwhite;
}
</style>
