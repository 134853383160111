<template>
  <div class="container-fluid mv-table-container mt-2 mb-4 px-0">
    <table class="table table-bordered mv-table">
      <thead class="mv-table__head">
        <tr class="mv-table__row">
          <th scope="col">Goal</th>
          <th scope="col">Proposed Measure</th>
          <th scope="col">Indicator Type</th>
          <th scope="col">Existing Data</th>
          <th scope="col">Comparative Data Available</th>
          <th scope="col">Possible Data Sources</th>
          <th scope="col">Research-Based</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody class="mv-table__body" id="mv-table-content">
        <tr v-for="(entry, i) in records" :key="i"  class="mv-table__row">
          <td class="mv-table__cell">
            <!-- eslint-disable-next-line -->
            <label class="visually-hidden" :for="(listType + 'goal' + i)">Goal</label>
            <input
              v-model="records[i].goal"
              @blur="updateRecord(listType, i, 'goal', records[i].goal);"
              placeholder="Goal..."
              class="mv-table__input"
              type="text"
              :id="(listType + 'goal' + i)"
              :name="(listType + 'goal' + i)">
          </td>
          <td class="mv-table__cell">
            <!-- eslint-disable-next-line -->
            <label class="visually-hidden" :for="(listType + 'proposedMeasure' + i)">Proposed Measure</label>
            <input
              v-model="records[i].proposedMeasure"
              @blur="updateRecord(listType, i, 'proposedMeasure', records[i].proposedMeasure);"
              placeholder="Proposed Measure..."
              class="mv-table__input"
              type="text"
              :id="(listType + 'proposedMeasure' + i)"
              :name="(listType + 'proposedMeasure' + i)">
          </td>
          <td class="mv-table__cell">
            <!-- eslint-disable-next-line -->
            <label class="visually-hidden" :for="(listType + 'indicatorType'+ i)">Indicator Type</label>
            <select
              v-model="records[i].indicatorType"
              @blur="updateRecord(listType, i, 'indicatorType', records[i].indicatorType);"
              class="form-select form-select-sm mv-table__select"
              type="text"
              :id="(listType + 'indicatorType' + i)"
              :name="(listType + 'indicatorType' + i)">
              <option
                disabled
                selected
                value="0">
                Indicator Type...
              </option>
              <option
                v-for="(option, optionValue) in indicatorTypeOpts"
                :key="optionValue">
                {{ option }}
              </option>
            </select>
          </td>
          <td class="mv-table__cell">
            <!-- eslint-disable-next-line -->
            <label class="visually-hidden" :for="(listType + 'existingData'+ i)">Existing Data</label>
            <select
              v-model="records[i].existingData"
              @blur="updateRecord(listType, i, 'existingData', records[i].existingData);"
              class="form-select form-select-sm mv-table__select"
              type="text"
              :id="(listType + 'existingData' + i)"
              :name="(listType + 'existingData' + i)">
              <option
                disabled
                selected
                value="0">
                Existing Data...
              </option>
              <option
                v-for="(option, optionValue) in existingDataOpts"
                :key="optionValue">
                {{ option }}
              </option>
            </select>
            <div
              v-if="records[i].existingData === 'Yes'">
              <!-- eslint-disable-next-line -->
              <label class="visually-hidden" :for="(listType + 'dataSource' + i)">Data Source: </label>
              <input
                v-model="dataSources[listType][i]"
                @blur="updateDataSource(listType, i, dataSources[listType][i]);"
                placeholder="Data Source..."
                class="mv-table__input"
                type="text"
                :id="(listType + 'dataSource' + i)"
                :name="(listType + 'dataSource' + i)">
            </div>
          </td>
          <td class="mv-table__cewll">
            <!-- eslint-disable-next-line -->
            <label class="visually-hidden" :for="(listType + 'comparativeDataAvailable'+ i)">Comparative Data Available</label>
            <select
              v-model="records[i].comparativeDataAvailable"
              @blur="updateRecord(listType, i, 'comparativeDataAvailable', records[i].comparativeDataAvailable);"
              class="form-select form-select-sm mv-table__select"
              type="text"
              :id="(listType + 'comparativeDataAvailable' + i)"
              :name="(listType + 'comparativeDataAvailable' + i)">
              <option
                disabled
                selected
                value="0">
                Comparative Data Available...
              </option>
              <option
                v-for="(option, optionValue) in comparativeDataAvailableOpts"
                :key="optionValue">
                {{ option }}
              </option>
            </select>
            <div
              v-if="records[i].comparativeDataAvailable === 'Yes'">
              <!-- eslint-disable-next-line -->
              <label class="visually-hidden" :for="(listType + 'comparativeDataSources' + i)">Comparative Data Source: </label>
              <input
                v-model="comparativeDataSources[listType][i]"
                @blur="updateComparativeDataSource(listType, i, comparativeDataSources[listType][i]);"
                placeholder="Comparative Data Source..."
                class="mv-table__input"
                type="text"
                :id="(listType + 'comparativeDataSources' + i)"
                :name="(listType + 'comparativeDataSources' + i)">
            </div>
          </td>
          <td class="mv-table__cell">
            <!-- eslint-disable-next-line -->
            <label class="visually-hidden" :for="(listType + 'possibleDataSources' + i)">Possible Data Sources</label>
            <input
              v-model="records[i].possibleDataSources"
              @blur="updateRecord(listType, i, 'possibleDataSources', records[i].possibleDataSources);"
              placeholder="Possible Data Sources..."
              class="mv-table__input"
              type="text"
              :id="(listType + 'possibleDataSources' + i)"
              :name="(listType + 'possibleDataSources' + i)">
          </td>
          <td class="mv-table__cell">
            <!-- eslint-disable-next-line -->
            <label class="visually-hidden" :for="(listType + 'researchBased'+ i)">Research-Based</label>
            <select
              v-model="records[i].researchBased"
              @blur="updateRecord(listType, i, 'researchBased', records[i].researchBased);"
              class="form-select form-select-sm mv-table__select"
              type="text"
              :id="(listType + 'researchBased' + i)"
              :name="(listType + 'researchBased' + i)">
              <option
                disabled
                selected
                value="0">
                Research-Based...
              </option>
              <option
                v-for="(option, optionValue) in researchBasedOpts"
                :key="optionValue">
                {{ option }}
              </option>
            </select>
            <div
              v-if="records[i].researchBased === 'Yes'">
              <!-- eslint-disable-next-line -->
              <label class="visually-hidden" :for="(listType + 'researchCitations' + i)">Citation: </label>
              <input
                v-model="researchCitations[listType][i]"
                @blur="updateResearchCitation(listType, i, researchCitations[listType][i]);"
                placeholder="Citation..."
                class="mv-table__input"
                type="text"
                :id="(listType + 'researchCitations' + i)"
                :name="(listType + 'researchCitations' + i)">
            </div>
          </td>
          <td class="mv-table__cell">
            <button class="btn btn-outline mv-icon-btn" @click="deleteRecord(listType, i);">
              <i class="bi bi-trash" aria-label="Delete Resource"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="d-flex justify-content-end">
      <button class="btn btn-sm btn-secondary" @click="addRecord(listType)">+ Add Row</button>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
export default {
  name: 'CommonAgendaMeasurementTable',

  props: {
    listType: {
      type: String,
      required: true,
      default: '',
    },
  },
  setup(props) {
    const store = useStore();

    return {
      records: computed(() => store.state.measurement[props.listType]),
      indicatorTypeOpts: computed(() => store.state.measurement.indicatorTypeOpts),
      existingDataOpts: computed(() => store.state.measurement.existingDataOpts),
      comparativeDataAvailableOpts: computed(() => store.state.measurement.comparativeDataAvailableOpts),
      researchBasedOpts: computed(() => store.state.measurement.researchBasedOpts),
      dataSources: computed(() => store.state.measurement.dataSources),
      comparativeDataSources: computed(() => store.state.measurement.comparativeDataSources),
      researchCitations: computed(() => store.state.measurement.researchCitations),
      addRecord: (type) => store.dispatch('measurement/addRecord', { type }),
      deleteRecord: (type, id) => {
        const c = confirm('Are you sure you want to delete this record?');
        if (c === true) {
          store.dispatch('measurement/deleteRecord', { type, id });
        }
      },
      updateRecord: (type, id, prop, value) => store.dispatch('measurement/updateRecord', { type, id, prop, value }),
      updateDataSource: (type, id, value) => store.dispatch('measurement/updateDataSource', { type, id, value }),
      updateComparativeDataSources: (type, id, value) => store.dispatch('measurement/updateComparativeDataSources', { type, id, value }),
      updateResearchCitation: (type, id, value) => store.dispatch('measurement/updateResearchCitation', { type, id, value }),
    };
  },
};
</script>

<style lang="scss" scoped>
.table > :not(:first-child) {
  border-bottom: inherit;
}
.mv-table {
  border: $form-table-border;
  font-size: $form-table-font-size;
}
.mv-table thead {
  border-bottom: $form-table-border;
}
.mv-table th {
  color: $form-table-label-text-color;
}
.mv-table__row {
  td,
  .mv-table__cell {
    padding: $form-table-cell-padding;
  }
  .mv-table__input,
  .mv-table__select {
    background: $form-table-input-bg;
    border:2px solid transparent;
    border-radius:3px;
    color: $mv_secondary_blue;
    font-size:14px;
    height:100%;
    padding:4px 2px;
    transition: $transition-default;
    width: $form-table-input-width;
    &:focus,
    &:focus-visible {
      border-color: $info;
      box-shadow:none;
      outline:none;
    }
  }
}
.mv-icon-btn {
  padding: 1px 4px;
  i {
    font-size: 1.1rem;
  }
}
.btn-info,
.btn-secondary,
.btn-success,
.btn-danger {
  &,
  &:link,
  &:visited,
  &:active,
  &:hover {
    color: $light;
  }
}
</style>
